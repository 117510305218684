#firebaseui-auth-container button {
  align-items:center;
  appearance:none;
  background-color:rgb(48, 63, 159);
  border-bottom-color:rgb(255, 255, 255);
  border-bottom-left-radius:4px;
  border-bottom-right-radius:4px;
  border-bottom-style:none;
  border-bottom-width:0px;
  border-image-outset:0;
  border-image-repeat:stretch;
  border-image-slice:100%;
  border-image-source:none;
  border-image-width:1;
  border-left-color:rgb(255, 255, 255);
  border-left-style:none;
  border-left-width:0px;
  border-right-color:rgb(255, 255, 255);
  border-right-style:none;
  border-right-width:0px;
  border-top-color:rgb(255, 255, 255);
  border-top-left-radius:4px;
  border-top-right-radius:4px;
  border-top-style:none;
  border-top-width:0px;
  box-shadow:rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px;
  box-sizing:border-box;
  color:rgb(255, 255, 255);
  cursor:pointer;
  display:inline-flex;
  font-family:Roboto, Helvetica, Arial, sans-serif;
  font-size:14px;
  font-stretch:100%;
  font-style:normal;
  font-variant-caps:normal;
  font-variant-east-asian:normal;
  font-variant-ligatures:normal;
  font-variant-numeric:normal;
  font-weight:500;
  height:36px;
  justify-content:center;
  letter-spacing:0.39998px;
  line-height:24.5px;
  margin-bottom:0px;
  margin-left:0px;
  margin-right:0px;
  margin-top:0px;
  min-width:64px;
  outline-color:rgb(255, 255, 255);
  outline-style:none;
  outline-width:0px;
  padding-bottom:6px;
  padding-left:16px;
  padding-right:16px;
  padding-top:6px;
  position:relative;
  text-align:center;
  text-decoration-color:rgb(255, 255, 255);
  text-decoration-line:none;
  text-decoration-style:solid;
  text-indent:0px;
  text-rendering:auto;
  text-shadow:none;
  text-transform:uppercase;
  transition-delay:0s, 0s, 0s;
  transition-duration:0.25s, 0.25s, 0.25s;
  transition-property:background-color, box-shadow, border;
  transition-timing-function:cubic-bezier(0.4, 0, 0.2, 1), cubic-bezier(0.4, 0, 0.2, 1), cubic-bezier(0.4, 0, 0.2, 1);
  user-select:none;
  vertical-align:middle;
  width:87.775px;
  word-spacing:0px;
  writing-mode:horizontal-tb;
  -webkit-font-smoothing:antialiased;
  -webkit-tap-highlight-color:rgba(0, 0, 0, 0);
  -webkit-border-image:none;

  margin-left: 10px;
  margin-top: 10px;
}

#firebaseui-auth-container label {
  left: 0;
}

#firebaseui-auth-container input {
  animation-duration:0.01s;
  animation-name:mui-auto-fill-cancel;
  appearance:none;
  background-attachment:scroll;
  background-clip:border-box;
  background-color:rgba(0, 0, 0, 0);
  background-image:none;
  background-origin:padding-box;
  background-position-x:0%;
  background-position-y:0%;
  background-size:auto;
  border-bottom-color:rgb(63, 81, 181);
  border-bottom-style:solid;
  border-bottom-width:1px;
  border-image-outset:0;
  border-image-repeat:stretch;
  border-image-slice:100%;
  border-image-source:none;
  border-image-width:1;
  border-left-color:rgba(0, 0, 0, 0.87);
  border-left-style:none;
  border-left-width:0px;
  border-right-color:rgba(0, 0, 0, 0.87);
  border-right-style:none;
  border-right-width:0px;
  border-top-color:rgba(0, 0, 0, 0.87);
  border-top-style:none;
  border-top-width:0px;
  box-shadow:none;
  box-sizing:content-box;
  color:rgba(0, 0, 0, 0.87);
  cursor:text;
  font-family:Roboto, Helvetica, Arial, sans-serif;
  font-size:16px;
  font-stretch:100%;
  font-style:normal;
  font-variant-caps:normal;
  font-variant-east-asian:normal;
  font-variant-ligatures:normal;
  font-variant-numeric:normal;
  font-weight:400;
  height:19px;
  letter-spacing:0.15008px;
  line-height:19.0016px;
  margin-bottom:0px;
  margin-left:0px;
  margin-right:0px;
  margin-top:0px;
  right: 0;
  min-width:0px;
  padding-bottom:7px;
  padding-left:0px;
  padding-right:0px;
  padding-top:6px;
  text-align:start;
  text-indent:0px;
  text-rendering:auto;
  text-shadow:none;
  text-transform:none;
  width:194.4px;
  word-spacing:0px;
  writing-mode:horizontal-tb;
  -webkit-font-smoothing:antialiased;
  -webkit-rtl-ordering:logical;
  -webkit-tap-highlight-color:rgba(0, 0, 0, 0);
  -webkit-border-image:none;
}

#firebaseui-auth-container input:focus {
  outline: none;
}

#firebaseui-auth-container .firebaseui-card-content {
  width: 300px;
  margin: auto;
}